import { useEffect, useState, useCallback } from "react";
import * as anchor from "@project-serum/anchor";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import Countdown from "react-countdown";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "../../utils/candy-machine";
import "./home.scss";

// Images
import { ReactComponent as Pow1 } from "../../assets/images/pow-1.svg";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";
import { ReactComponent as Discord } from "../../assets/icons/discord.svg";
import { ReactComponent as Sponsor } from "../../assets/icons/logo_nftmonitor.svg";
import { ReactComponent as SolParasites } from "../../assets/icons/sol_parasites.svg";
import { ReactComponent as Flash } from "../../assets/icons/flash.svg";
import pow3 from "../../assets/images/pow-3.png";
import group1 from "../../assets/images/headers/group-1.png";
import group2 from "../../assets/images/headers/group-2.png";
import group3 from "../../assets/images/headers/group-3.png";
import group4 from "../../assets/images/headers/group-4.png";
import group5 from "../../assets/images/headers/group-5.png";
import group6 from "../../assets/images/headers/group-6.png";
import group7 from "../../assets/images/headers/group-7.png";
import banner from "../../assets/images/banner.png";

import santa01_01 from "../../assets/images/rarities/santa01_01.png";
import santa01_02 from "../../assets/images/rarities/santa01_02.png";
import santa01_03 from "../../assets/images/rarities/santa01_03.png";
import santa01_04 from "../../assets/images/rarities/santa01_04.png";
import santa01_05 from "../../assets/images/rarities/santa01_05.png";
import santa02_01 from "../../assets/images/rarities/santa02_01.png";
import santa02_02 from "../../assets/images/rarities/santa02_02.png";
import santa02_03 from "../../assets/images/rarities/santa02_03.png";
import santa02_04 from "../../assets/images/rarities/santa02_04.png";
import santa02_05 from "../../assets/images/rarities/santa02_05.png";
import santa03_01 from "../../assets/images/rarities/santa03_01.png";
import santa03_02 from "../../assets/images/rarities/santa03_02.png";
import santa03_03 from "../../assets/images/rarities/santa03_03.png";
import santa03_04 from "../../assets/images/rarities/santa03_04.png";
import santa03_05 from "../../assets/images/rarities/santa03_05.png";
import santa04_01 from "../../assets/images/rarities/santa04_01.png";
import santa04_02 from "../../assets/images/rarities/santa04_02.png";
import santa04_03 from "../../assets/images/rarities/santa04_03.png";
import santa04_04 from "../../assets/images/rarities/santa04_04.png";
import santa04_05 from "../../assets/images/rarities/santa04_05.png";
import santa05_01 from "../../assets/images/rarities/santa05_01.png";
import santa05_02 from "../../assets/images/rarities/santa05_02.png";
import santa05_03 from "../../assets/images/rarities/santa05_03.png";
import santa05_04 from "../../assets/images/rarities/santa05_04.png";
import santa05_05 from "../../assets/images/rarities/santa05_05.png";
import santa06_01 from "../../assets/images/rarities/santa06_01.png";
import santa06_02 from "../../assets/images/rarities/santa06_02.png";
import santa06_03 from "../../assets/images/rarities/santa06_03.png";
import santa06_04 from "../../assets/images/rarities/santa06_04.png";
import santa06_05 from "../../assets/images/rarities/santa06_05.png";
import santa07_01 from "../../assets/images/rarities/santa07_01.png";
import santa07_02 from "../../assets/images/rarities/santa07_02.png";
import santa07_03 from "../../assets/images/rarities/santa07_03.png";
import santa07_04 from "../../assets/images/rarities/santa07_04.png";
import santa07_05 from "../../assets/images/rarities/santa07_05.png";
import santa08_01 from "../../assets/images/rarities/santa08_01.png";
import santa08_02 from "../../assets/images/rarities/santa08_02.png";
import santa08_03 from "../../assets/images/rarities/santa08_03.png";
import santa08_04 from "../../assets/images/rarities/santa08_04.png";
import santa08_05 from "../../assets/images/rarities/santa08_05.png";
import santa09_01 from "../../assets/images/rarities/santa09_01.png";
import santa09_02 from "../../assets/images/rarities/santa09_02.png";
import santa09_03 from "../../assets/images/rarities/santa09_03.png";
import santa09_04 from "../../assets/images/rarities/santa09_04.png";
import santa09_05 from "../../assets/images/rarities/santa09_05.png";

const RARITIES = [
  {
    id: 1,
    name: "Santa Clause",
    description: "",
    percentage: 84,
    images: [santa01_01, santa01_02, santa01_03, santa01_04, santa01_05],
  },
  {
    id: 2,
    name: "Ape Santa",
    description: "",
    percentage: 4,
    images: [santa02_01, santa02_02, santa02_03, santa02_04, santa02_05],
  },
  {
    id: 3,
    name: "Frenchie Santa",
    description: "",
    percentage: 1,
    images: [santa03_01, santa03_02, santa03_03, santa03_04, santa03_05],
  },
  {
    id: 4,
    name: "Red Panda Santa",
    description: "",
    percentage: 2,
    images: [santa04_01, santa04_02, santa04_03, santa04_04, santa04_05],
  },
  {
    id: 5,
    name: "Jungle Cat Santa",
    description: "",
    percentage: 1,
    images: [santa05_01, santa05_02, santa05_03, santa05_04, santa05_05],
  },
  {
    id: 6,
    name: "Meerkat Santa",
    description: "",
    percentage: 3,
    images: [santa06_01, santa06_02, santa06_03, santa06_04, santa06_05],
  },
  {
    id: 7,
    name: "Kitten Santa",
    description: "",
    percentage: 3,
    images: [santa07_01, santa07_02, santa07_03, santa07_04, santa07_05],
  },
  {
    id: 8,
    name: "Chicken Santa",
    description: "",
    percentage: 1,
    images: [santa08_01, santa08_02, santa08_03, santa08_04, santa08_05],
  },
  {
    id: 9,
    name: "Penguin Santa",
    description: "",
    percentage: 1,
    images: [santa09_01, santa09_02, santa09_03, santa09_04, santa09_05],
  },
];

const ConnectButton = styled(WalletDialogButton)`
  background-color: #57bcd8;
  color: #071d40;
  padding: 1rem 3rem;
  border-radius: 4rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.6rem;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 2.4rem 9.3rem 0 rgba(0, 0, 0, 0.16);
  border: none;
  font-weight: 600;

  &:hover {
    background-color: #41d2fa;
  }
`;

const CounterText = styled.span``; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}
export default function Home(props: HomeProps) {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = useCallback(async () => {
    if (!wallet) return;

    const {
      candyMachine,
      goLiveDate,
      itemsAvailable,
      itemsRemaining,
      itemsRedeemed,
    } = await getCandyMachineState(
      wallet as anchor.Wallet,
      props.candyMachineId,
      props.connection
    );

    setItemsAvailable(itemsAvailable);
    setItemsRemaining(itemsRemaining);
    setItemsRedeemed(itemsRedeemed);

    setIsSoldOut(itemsRemaining === 0);
    setStartDate(goLiveDate);
    setCandyMachine(candyMachine);
  }, [wallet, props.candyMachineId, props.connection]);

  const onMint = useCallback(async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  }, [
    candyMachine,
    props.config,
    props.connection,
    props.treasury,
    props.txTimeout,
    refreshCandyMachineState,
    wallet,
  ]);

  useEffect(() => {
    async function getBalance() {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    }

    getBalance();
  }, [wallet, props.connection]);

  useEffect(() => {
    refreshCandyMachineState();
  }, [refreshCandyMachineState]);

  return (
    <div className="wrapper">
      {/* Header */}
      <div className="header">
        <div className="header__container container">
          <div className="header__logo">
          <a href="https://nftmonitor.io"><Logo /></a>
          </div>
          <div className="header__contact">
            <a
              className="header__contact__twitter"
              href="https://twitter.com/nftmonitor_io"
              target="_blank"
              rel="noreferrer"
            >
              <Twitter />
            </a>
            <a
              className="header__contact__discover"
              href="https://discord.com/invite/UMuHcwbVtE"
              target="_blank"
              rel="noreferrer"
            >
              <Discord />
            </a>
          </div>
        </div>
      </div>
      <div className="body">
        {/* Heading */}
        <div className="heading">
          <div className="heading__bg" />
          <div className="heading__container container">
            <h1>
              METAVERSE <span>SANTAS</span>
            </h1>
            <div className="heading__character">
              <img src={group1} alt="group1" />
            </div>
            <div className="heading__character">
              <img src={group2} alt="group2" />
            </div>
            <div className="heading__character">
              <img src={group3} alt="group3" />
            </div>
            <div className="heading__character">
              <img src={group4} alt="group4" />
            </div>
            <div className="heading__character">
              <img src={group5} alt="group5" />
            </div>
            <div className="heading__character">
              <img src={group6} alt="group6" />
            </div>
            <div className="heading__character">
              <img src={group7} alt="group7" />
            </div>
          </div>
          <div className="heading__banner">
            <div className="heading__banner__container">
              <img src={banner} alt="banner" />
            </div>
          </div>
        </div>

        {/* Banner */}
        <div className="banner">
          <div className="banner__container container">
            <div className="banner__flash">
              <Flash />
            </div>
            <div className="banner__content">
              <div className="banner__content__mint__price">
                Mint Price: <span>0.01 SOL</span>
              </div>
              <div className="banner__content__mint__time">
                Minting NOW!
              </div>
              <div className="banner__content__wallet__address">
                {wallet &&
                  `Wallet ${shortenAddress(wallet.publicKey.toBase58() || "")}`}
              </div>
              <div className="banner__content__wallet__redeemed">
                {wallet && ` Redeemed: ${itemsRedeemed} / ${itemsAvailable}`}
              </div>
              <div className="banner__content__action">
                {!wallet ? (
                  <ConnectButton />
                ) : (
                  <button
                    disabled={isSoldOut || isMinting || !isActive}
                    onClick={onMint}
                  >
                    {isSoldOut ? (
                      "SOLD OUT"
                    ) : isActive ? (
                      isMinting ? (
                        <CircularProgress />
                      ) : (
                        "MINT"
                      )
                    ) : (
                      <Countdown
                        date={startDate}
                        onMount={({ completed }) =>
                          completed && setIsActive(true)
                        }
                        onComplete={() => setIsActive(true)}
                        renderer={renderCounter}
                      />
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Info */}
        <div className="info container">
          <div className="info__heading">
            <h3>What is unique about Metaverse Santas?</h3>
          </div>
          <div className="info__sub-heading">
            Metasverse Santas are a collection of 9999 unique 1/1 NFTs. Apart
            from regular Santas they cover a wide spectrum of the Metaverse with
            rare Ape Santas, Red Panda Santas, Jungle Cat Santas, Meerkat Santas
            and more.
          </div>
        </div>
        {/* Rarity */}
        <div className="rarity container">
          <div className="rarity__heading">
            <div className="rarity__main-heading">
              <h3>Rarity</h3>
            </div>
            <div className="rarity__sub-heading">
              All Metaverse Santas are unique 1/1 NFTs.
              <br />
              Besides that, there are the following rarites:
            </div>
          </div>

          <div className="rarity__list">
            {RARITIES.map((rarity) => (
              <div key={rarity.id} className="rarity__item">
                <div className="rarity__item__info">
                  <div className="rarity__item__percentage">
                    {rarity.percentage}%
                  </div>
                  <div className="rarity__item__name">
                    <div className="rarity__item__name__title">
                      {rarity.name}
                    </div>
                    <div className="rarity__item__name__description">
                      {rarity.description}
                    </div>
                  </div>
                </div>
                <div className="rarity__item__images">
                  {rarity.images.map((img, index) => (
                    <div key={index} className="rarity__item__image">
                      <img src={img} alt="img" />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* FAQ */}
        <div className="faq container">
          <h3>Frequently Asked Questions</h3>
          <div className="faq__accordions">
            <ul>
              <li>
                <input type="checkbox" id="list-item-1" />
                <label htmlFor="list-item-1">What is the mint price?</label>
                <ul>
                  <li>
                    The mint price is 0.01 SOL - so it is more or less a FREE
                    mint. There will be the standard Solana mint transaction
                    costs on top of it.
                  </li>
                </ul>
              </li>

              <li>
                <input type="checkbox" id="list-item-2" />
                <label htmlFor="list-item-2">
                  Is there a limit on how many NFTs I can mint.
                </label>
                <ul>
                  <li>No. Feel free to mint as many as you want / can get.</li>
                </ul>
              </li>
              <li>
                <input type="checkbox" id="list-item-3" />
                <label htmlFor="list-item-3">
                  What about Roadmap / DAO / Secondary Market / ... ?
                </label>
                <ul>
                  <li>
                    This is a No-Frills NFT. Buy it as a NFT gift, enjoy the art
                    and have a great Christmas Time!
                    <br />
                    That said, we will try to get it verified and listed
                    (targeting ME and Exchange.art) - but no promises.
                    <br />
                    Holders will also have access to whitelisted future
                    mints/functionality rollouts of nftmonitor.io
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        {/* Team */}
        <div className="team container">
          <div className="team__intro">
            <h3>Team</h3>
            <div className="team__intro__description">
	  This NFT is sponsored by nftmonitor.io
            </div>
          </div>

          <div className="team__members">
            <div className="team__member">
              <div className="team__member__logo">
                <Sponsor />
              </div>
              <div className="team__member__name">nftmonitor.io</div>
              <div className="team__member__type">Sponsor</div>
              <div className="team__member__hashtag">@nftmonitor_io</div>
            </div>

            <div className="team__member">
              <div className="team__member__logo">
                <SolParasites />
              </div>
              <div className="team__member__name">SOL Parasites</div>
              <div className="team__member__type">AI Artists</div>
              <div className="team__member__hashtag">@SOLParasitesNFT</div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="footer">
        <div className="footer__container container">
          <div className="footer__powered-by">
            Powered by
            <div className="footer__logos">
              <Pow1 />
              <img src={pow3} alt="pow_3" />
            </div>
          </div>
          <div className="footer__copyright">
            Copyright 2021 by nftmonitor.io
          </div>
        </div>
      </footer>
    </div>
  );
}

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};
